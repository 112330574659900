.ai-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.divider {
  width: 4em;
  border-left: 10px solid white;
  height: 500px;
  margin-left: 4em;
}
