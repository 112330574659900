.logo-container {
  position: absolute;
  background-color: greenyellow;
  right: 0px;
  top: 0px;
}

.logo {
  height: 100px;
  width: 100px;
}
