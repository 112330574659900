.form-label {
  width: 100%;
  text-align: center;
}

#dataInfoButton {
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
}

.alert {
  margin-top: 1em;
}