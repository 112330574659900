.App {
  text-align: center;
}

.app-with-menu-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

:root {
  --bs-body-bg: #203c49;
  --bs-body-color: #ffffffde;
  --trm-element-bg: #112128;
}

.navigation-rail + div {
  margin-left: 72px;
}
