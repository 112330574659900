.home-container {
  width: 100%;
  position: relative;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
