.modal-content,
.form-select {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}

/* New */

.qodMainContainer {
  display: grid;
  grid-template-columns: 520px 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'graph data';
  width: 100%;
  padding: 2em;
  height: 100%;
  position: relative;
}

.qod-container::after,
.qodMainContainer::after,
.dataTableContainer::after {
  content: '';
  clear: both;
  display: table;
}

.graphContainer {
  grid-area: graph;
}

.dataTableContainer {
  grid-area: data;
  margin-bottom: 2em;
}

.processingButtonsContainer {
  display: flex;
  margin-bottom: 1rem;
}

.processingButtonsContainer > Button:not(:first-child) {
  margin-left: 0.5em;
}

.processingButtonsContainer > Button {
  flex-grow: 1;
}

.dataTableContainer Table th {
  background-color: #323539;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingOverlay > .loadingText {
  font-size: 40px;
  margin-left: 0.25em;
}

.eitlBox {
  display: inline-block;
}

.eitlBox div {
  display: inline-block;
  padding-right: 1em;
}

.downloadButton {
  display: block;
}
