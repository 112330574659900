.option-select-container {
  top: 125px;
  left: 50px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
}

.option-select-arrow {
  animation: ani 1.7s infinite;
  position: absolute;
}

.option-select-text {
  margin-left: 100px;
  margin-bottom: 5px;
}

@keyframes ani {
  0% {
    left: 0;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 0;
  }
}
