@keyframes loader {
  to {transform: rotate(360deg);}
}

.loader-container {
  position: relative;
  width: 20px;
  height: 20px;
}

.loader-container::after {
  content: "";
  clear: both;
  display: table;
}
 
.loader {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: loader .6s linear infinite;
}

.loaderText {
  margin-left: 25px;
}