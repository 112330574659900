.navigation-rail {
  height: 100vh;
  background-color: var(--trm-element-bg);
  width: 72px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: none;
  position: fixed;
}

.navigation-child,
.navigation-link {
  width: 72px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  color: inherit;
  text-decoration: none;
}

.navigation-link:hover {
  color: inherit;
}

.navigation-child:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.navigation-child:active {
  background-color: rgba(255, 255, 255, 0.6);
}
